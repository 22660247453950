<!-- Breadcrumb -->
<div class="breadcrumb">
  <h2 class="current-page">dashboard ({{siteName}})</h2>
</div>


<ui-loader [loaderId]="siteDashboardLoaderId"
           class="shadow-clean"
           [disableNoData]="true">

  <div class="grid">

    <div>
      <!-- Card -->
      <div class="card padding margin-bottom">
        <h2>Products</h2>

        <div class="inline-wrap">

          <!-- Card -->
          <div class="card-white padding margin-bottom mute-text ">
            <div class="value">
              <div>
                <mat-icon>hourglass_empty</mat-icon>
              </div>
              <div>
                <h1>29</h1>
                Hidden products
              </div>
            </div>
          </div>

          <!-- Card -->
          <div class="card-white padding margin-bottom success-text">
            <div class="value">
              <div>
                <mat-icon>check_circle</mat-icon>
              </div>
              <div>
                <h1>
                  {{dashboardInfo.CollectionCountTotal}}
                </h1>
                Collections created
              </div>
            </div>
          </div>

          <!-- Card -->
          <div class="card-white padding margin-bottom success-text">
            <div class="value">
              <div>
                <mat-icon>check_circle</mat-icon>
              </div>
              <div>
                <h1>
                  {{dashboardInfo.CollectionSchemasTotalCount}}
                </h1>
                Schemas created
              </div>
            </div>
          </div>



        </div>
        <div class="wrap-button">

          <!-- Bottone -->
          <button mat-raised-button
                  color='primary'
                  [routerLink]="'../manage/publications'">
            <mat-icon>input</mat-icon>Product Management
          </button>
        </div>
      </div>
      <div>

        <!-- Card -->
        <div class="card padding">
          <h2>Customers</h2>
          <div class="inline-wrap">

            <!-- Card -->
            <div class="card-white padding margin-bottom error-text">
              <div class="value">
                <div>
                  <mat-icon>notification_important</mat-icon>
                </div>
                <div>
                  <h1>
                    {{dashboardInfo.PendingRequestTotalCount}}
                  </h1>
                  Check-in approval pending requests
                </div>
              </div>
            </div>
          </div>

          <div class="wrap-button">
            <!-- Bottone -->
            <button mat-raised-button
                    color='primary'
                    [routerLink]="'../company/checkin'">
              <mat-icon>input</mat-icon>Customer management
            </button>
          </div>
        </div>
      </div>

    </div>


    <!-- Card -->
    <div class="card padding">
      <h2>catalogs</h2>


      <!-- Card -->
      <div class="card-white padding margin-bottom margin-right">
        <div class="value">
          <div>
            <mat-icon>style</mat-icon>
          </div>
          <div>
            <h1>{{dashboardInfo.CatalogueTotalCount}}</h1>
            Total catalogs created
          </div>
        </div>
      </div>

      <div class="center"
           *ngIf="catalogChart">
        <div class="chart margin-bottom">
          <canvas baseChart
                  width="250"
                  height="230"
                  [data]="catalogChart.data"
                  [labels]="catalogChart.labels"
                  [options]="catalogChart.options"
                  [colors]="catalogChart.colors"
                  [legend]="catalogChart.legend"
                  [chartType]="catalogChart.type">
          </canvas>

        </div>
      </div>


      <div class="valign-wrapper">
        <!-- Card -->
        <div class="card-white padding margin-bottom success-text">
          <div class="value">
            <div>
              <mat-icon>check_circle</mat-icon>
            </div>
            <div>
              <h1>{{dashboardInfo.PublishedCatalogueCount}}</h1>
              Published catalogs
            </div>
          </div>
        </div>

        <!-- Card -->
        <div class="card-white padding margin-bottom mute-text ">
          <div class="value">
            <div>
              <mat-icon>hourglass_empty</mat-icon>
            </div>
            <div>
              <h1>{{dashboardInfo.CatalogueTotalCount - dashboardInfo.PublishedCatalogueCount}}</h1>
              Inactive catalogs
            </div>
          </div>
        </div>

      </div>

      <!-- Bottone -->
      <div class="wrap-button">
        <button mat-raised-button
                color='primary'
                [routerLink]="'../manage/catalogs'">
          <mat-icon>input</mat-icon>Catalog Management
        </button>
      </div>
    </div>
    <!-- Card -->
    <div class="card padding">
      <h2>Promotions</h2>

      <!-- Card -->
      <div class="card-white padding margin-bottom margin-right">
        <div class="value">
          <div>
            <mat-icon>star</mat-icon>
          </div>
          <div>
            <h1>{{dashboardInfo.PromotionTotalCount}}</h1>
            Total promotions created
          </div>
        </div>
      </div>

      <div class="center"
           *ngIf="promotionChart">
        <div class="chart margin-bottom">
          <canvas baseChart
                  width="250"
                  height="230"
                  [data]="promotionChart.data"
                  [labels]="promotionChart.labels"
                  [options]="promotionChart.options"
                  [colors]="promotionChart.colors"
                  [legend]="promotionChart.legend"
                  [chartType]="promotionChart.type">
          </canvas>

        </div>
      </div>





      <div class="valign-wrapper">



        <!-- Card -->
        <div class="card-white padding margin-bottom success-text ">
          <div class="value">
            <div>
              <mat-icon>check_circle</mat-icon>
            </div>
            <div>
              <h1>{{dashboardInfo.PromotionPublishedTotalCount}}</h1>
              Active running promotions
            </div>
          </div>
        </div>

        <!-- Card -->
        <div class="card-white padding margin-bottom mute-text ">
          <div class="value">
            <div>
              <mat-icon>hourglass_empty</mat-icon>
            </div>
            <div>
              <h1>{{dashboardInfo.PromotionTotalCount - dashboardInfo.PromotionPublishedTotalCount}}</h1>
              Inactive promotions
            </div>
          </div>
        </div>
      </div>


      <div class="wrap-button">
        <!-- Bottone -->
        <button mat-raised-button
                color='primary'
                [routerLink]="'../manage/promotions'">
          <mat-icon>input</mat-icon>Promotion management
        </button>
      </div>
    </div>

  </div>
</ui-loader>
