<!-- Breadcrumb -->
<div class="breadcrumb">
  <h2>HOME</h2>
  <mat-icon>keyboard_arrow_right</mat-icon>
  <h2 class="current-page">PRODUCT OVERVIEW</h2>
</div>


<!-- Card -->
<div class="card padding margin-top ">
  <h2>SEARCH</h2>

  <div class="valign-wrapper margin-bottom-small">
    <mat-form-field appearance="outline"
                    class="margin-right">
      <input matInput
             placeholder="Collection">
      <mat-label>Collection</mat-label>
    </mat-form-field>

    <mat-form-field appearance="outline"
                    class="margin-right">
      <input matInput
             placeholder="Product code">
      <mat-label>Product code</mat-label>
    </mat-form-field>


    <div class="valign-wrapper">
      <b class="margin-left-small margin-right-small">Visibility: </b>
      <mat-button-toggle-group>
        <mat-button-toggle>
          All
        </mat-button-toggle>
        <mat-button-toggle>
          Visible
        </mat-button-toggle>
        <mat-button-toggle>
          Hidden
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>


    <div class="valign-wrapper">
      <b class="margin-left margin-right-small">Status: </b>
      <mat-button-toggle-group>
        <mat-button-toggle>
          All
        </mat-button-toggle>
        <mat-button-toggle>
          Instock
        </mat-button-toggle>
        <mat-button-toggle>
          Incoming
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>




  <div class="right-align margin-top">

    <button mat-raised-button
            color='primary'>
      <mat-icon>search</mat-icon>Search!
    </button>
  </div>


</div>



<!-- Card -->
<div class="card padding margin-top">
  <h2>RESULTS</h2>

  <div class="right-align margin-bottom">
    <mat-paginator [hidePageSize]="true">
    </mat-paginator>
  </div>


  <kendo-grid [kendoGridBinding]="tableRecords">

    <kendo-grid-column title="Collection">
      123321
    </kendo-grid-column>


    <kendo-grid-column title="Product Code">
      123321
    </kendo-grid-column>


    <kendo-grid-column title="In stock" width="130">
      <ng-template kendoGridCellTemplate
                   let-dataItem>
        <button mat-stroked-button>
          <mat-icon>remove_red_eye</mat-icon> Details
        </button>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Incoming"  width="130">
      <ng-template kendoGridCellTemplate
                   let-dataItem>
        <button mat-stroked-button>
          <mat-icon>remove_red_eye</mat-icon> Details
        </button>
      </ng-template>

    </kendo-grid-column>


    <kendo-grid-column title="Visibility"  width="130">
      <ng-template kendoGridCellTemplate
                   let-dataItem>

        <mat-slide-toggle>Visible</mat-slide-toggle>
      </ng-template>
    </kendo-grid-column>

  </kendo-grid>



  <div class="right-align margin-top">
    <mat-paginator [hidePageSize]="true">
    </mat-paginator>
  </div>


</div>
