<div class="dropdown up-area-selector">
  <button class="dropbtn">
    <div (click)="showMenu = !showMenu">
      <mat-icon>view_module</mat-icon>
      <a>{{currentArea.DisplayName}}</a>
      <mat-icon>{{showMenu?'expand_more':'expand_less'}}</mat-icon>
    </div>
  </button>
  <div class="dropdown-content"
       [ngClass]="{'show-menu': showMenu}">
    <a class="area-link"
       *ngFor="let area of displayedAreas"
       (click)="navigateToArea(area)"> {{area.DisplayName}}</a>
  </div>
</div>