import { Component, OnInit } from '@angular/core';
import { UiDialogService } from '@basic/libs';
import { ProductOverviewRow } from 'src/app/shared/models/product-overview-row';

@Component({
  selector: 'cms-product-overview',
  templateUrl: './product-overview.component.html',
  styleUrls: ['./product-overview.component.scss']
})

export class ProductOverviewComponent implements OnInit {


  public tableRecords: ProductOverviewRow[] = [];


  constructor(private messageService: UiDialogService) {
    this.tableRecords.push(new ProductOverviewRow());
    this.tableRecords.push(new ProductOverviewRow());
    this.tableRecords.push(new ProductOverviewRow());

  }

  ngOnInit() {
  }

}
