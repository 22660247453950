<ui-loader [loaderId]="loaderId">
  <ul class="center">
    <li *ngFor="let site of sites"
        class="site-list-wrapper">
      <mat-card>
        <mat-card-content>
          <div class="site-wrapper">
            <div class="site-image"><img src="{{site.ImageUrl}}"
                   title="{{site.SiteName}}" /></div>
            <div class="site-links ">
              <div class=" site-link center ">
                <button mat-button
                        [routerLink]="[site.RouteName,'dashboard']">Shop management</button>
              </div>
              <div class=" site-link center ">
                <button mat-button
                        (click)="goTo(site.B2BUrl)"
                        [disabled]="!site.B2BUrl">4Shops</button>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </li>
  </ul>
</ui-loader>