import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MenuItems, MenuRoot } from '../menu-builder.models';
import { Guid, Utilities } from '@basic/libs';
import { MenuBuilderService } from '../menu-builder.service';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelectChange } from '@angular/material/select';

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  id: number;
}

@Component({
  selector: 'cms-add-dialog',
  templateUrl: './add-dialog.component.html',
  styleUrls: ['./add-dialog.component.scss']
})
export class AddDialogComponent implements OnInit {

  @Output() aply: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() categorySel: EventEmitter<any> = new EventEmitter();
  @Input() menuRoot: MenuRoot[];
  @Input() subCategories: MenuItems[];
  @Input() categorySelected: MenuItems;
  @Input() subMenuSlot: MenuItems;

  subOptionsLoaderId: string = Guid.newGuid();
  subOptions: MenuItems[];
  subOptionsTree: MenuItems[] = [];
  filteredData: MenuItems[];
  checklistSelection = new SelectionModel<ExampleFlatNode>(true /* multiple */);
  disableSelect: boolean = false;
  show: boolean = true;
  initialSelectionLength: number;
  disableAply: boolean = true;

  treeControl = new FlatTreeControl<ExampleFlatNode>(node => node.level, node => node.expandable);


  _transformer = (node: MenuItems, level: number) => {
    return {
      expandable: !!node.SubMenu && node.SubMenu.length > 0,
      name: node.Description,
      level: level,
      id: node.Id
    };
  }

  // tslint:disable-next-line:member-ordering
  treeFlattener = new MatTreeFlattener(this._transformer, node => node.level, node => node.expandable, node => node.SubMenu);
  // tslint:disable-next-line:member-ordering
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  // tslint:disable-next-line:member-ordering
  constructor(private menuBuilderService: MenuBuilderService) {
    this.dataSource.data = [];
  }

  ngOnInit() {

    if (this.categorySelected.Description !== '') {
      if (this.categorySelected.Description === 'Gender') {
        this.disableSelect = true;
      }
      this.menuBuilderService.getOptions(this.categorySelected.ObjectTypeId, true, this.subOptionsLoaderId).subscribe(
        (result: MenuItems[]) => {
          this.subOptions = result;
          this.filterData(this.categorySelected);
          this.buildTree(this.categorySelected);
          if (this.categorySelected.Id === 0) {
            this.checkMenuOptionSelected();
          } else {
            this.checkOptionsSelected();
          }
          this.checklistSelection.changed.subscribe(
            x => {
              this.disableAply = false;
            }
          );
          this.initialSelectionLength = this.checklistSelection.selected.length;
        }
      );
    }
  }

  /** Whether all the descendants of the node are selected */
  descendantsAllSelected(node: ExampleFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.every(child => this.checklistSelection.isSelected(child));
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: ExampleFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: ExampleFlatNode): void {
    this.checklistSelection.toggle(node);
  }

  selectAllSubOptions(event: MatCheckboxChange) {
    this.treeControl.dataNodes.forEach(node => {
      (event.checked && node.level === 0)
        ? this.checklistSelection.select(node)
        : this.checklistSelection.deselect(node);
    });
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  closeDialog() {
    this.close.emit();
  }

  save() {
    const itemsChecked: MenuItems[] = [];
    let item: MenuItems = new MenuItems;
    this.checklistSelection.selected.forEach(elem => {
      item = this.subOptionsTree.find(itemToFind => itemToFind.Description === elem.name);
      itemsChecked.push(Utilities.DeepClone<MenuItems>(item));
    });
    this.aply.emit(itemsChecked);

  }

  subCategorySelected(event: MatSelectChange) {
    if (event.value.DescriptionValue !== '') {
      this.menuBuilderService.getOptions(event.value.ObjectTypeId, true, this.subOptionsLoaderId).subscribe(
        (result: MenuItems[]) => {
          this.subOptions = result;
          this.filterData(event.value);
          this.buildTree(event.value);
          this.checklistSelection.clear();
          this.show = true;
        }
      );
    } else {
      this.show = false;
      this.dataSource.data = [];
    }
    this.categorySel.emit(event.value);
  }

  private checkMenuOptionSelected() {
    this.menuRoot.forEach(option => {
      const node = this.treeControl.dataNodes.find(elem => elem.id === option.Id);
      if (node) {
        this.checklistSelection.select(node);
      }
    });
  }

  private checkOptionsSelected() {
    this.subMenuSlot.SubMenu.forEach(item => {
      const node = this.treeControl.dataNodes.find(elem => elem.name === item.Description);
      if (node) {
        this.checklistSelection.select(node);
      }
    });
  }

  private buildTree(choice: MenuItems) {
    this.subOptionsTree = [];
    if (this.filteredData.length > 0) {
      this.filteredData.forEach(node => {
        if (this.filteredData.indexOf(node) > 0) {
          if (!node.ParentObjectId || node.ParentObjectId === choice.Id) { return this.subOptionsTree.push(node); }
          const parentIndex = this.filteredData.findIndex(el => el.Id === node.ParentObjectId);
          if (!this.filteredData[parentIndex].SubMenu) {
            return this.filteredData[parentIndex].SubMenu = [node];
          }
          this.filteredData[parentIndex].SubMenu.push(node);
        }
      });
    }
    this.dataSource.data = this.subOptionsTree;
  }

  private filterData(choice: MenuItems) {
    let filteredTreeData: MenuItems[] = [];
    filteredTreeData[0] = new MenuItems;
    filteredTreeData[0] = this.subOptions.find(elem => elem.Id === choice.Id);
    if (choice.Id === 0) {
      filteredTreeData = this.subOptions;
    }
    let keepGoing = 0;
    let childsAreFound = true;
    let childsNodes: MenuItems[];

    while (childsAreFound) {
      keepGoing = 0;
      filteredTreeData.forEach(element => {
        keepGoing++;
        childsNodes = this.findAllChilds(element.Id);
        childsNodes.forEach(node => {
          if (node && (filteredTreeData.indexOf(node) === -1)) {
            filteredTreeData.push(node);
          }
        });
      });
      childsAreFound = keepGoing !== filteredTreeData.length;
    }

    this.filteredData = Utilities.DeepClone<MenuItems[]>(filteredTreeData);
  }

  private findAllChilds(id: number): MenuItems[] {
    const childs: MenuItems[] = [];
    this.subOptions.forEach(node => {
      if (node.ParentObjectId === id) {
        node['SubMenu'] = [];
        childs.push(node);
      }
    });
    return childs;
  }

}
