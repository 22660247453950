import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BasicHttpClientService, ContextService, DateUtilities, PageResult, ObjectId, UiDialogService } from '@basic/libs';

import { Catalog } from '../../shared/models/common.models';
import { ErrorHandlerService } from '../../shared/services/error-handler.service';
import { Subject, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AvailabilityType } from './catalog.models';

@Injectable({ providedIn: 'root' })
export class CatalogService {
  private baseApiUrl = this.contextService.context.Settings.AppPaths.Local.Api + '/catalogs';

  private _cachedAvailabilityTypes: AvailabilityType[];

  constructor(private httpclient: BasicHttpClientService, private contextService: ContextService, private errorHandlerService: ErrorHandlerService,
    private messageService: UiDialogService) {
  }

  getCatalogs(currentPage?: number, pageSize?: number, searchText?: string, startDate?: Date,
    endDate?: Date, IsPublished?: string, loaderId?: string) {
    const subj = new Subject<PageResult<Catalog>>();
    const params = new HttpParams({
      fromObject: {
        currentPage: currentPage.toString(),
        pageSize: pageSize.toString(),
        searchText: searchText || '',
        startDate: DateUtilities.FormatDateForAPI(startDate),
        endDate: DateUtilities.FormatDateForAPI(endDate),
        IsPublished: IsPublished === 'All' ? null : IsPublished === 'Published' ? true.toString() : IsPublished === 'Unpublished' ? false.toString() : null
      }
    });
    this.httpclient.get<PageResult<Catalog>>(
      {
        url: this.baseApiUrl + '/GetCatalogs',
        options: { params: params, withCredentials: true },
        loaderId: loaderId
      }, res => subj.next(res), (er) => { this.errorHandlerService.handleError(er); subj.error(er); });
    return subj.asObservable();
  }

  getCatalog(catalogId: number, loaderId?: string) {
    const params = new HttpParams({
      fromObject: {
        catalogId: catalogId.toString()
      }
    });
    const subj = new Subject<Catalog>();
    this.httpclient.get<Catalog>({
      url: this.baseApiUrl + '/GetCatalogById',
      options: { params: params, withCredentials: true },
      loaderId: loaderId
    }, (val) => subj.next(val),
      (er) => { this.errorHandlerService.handleError(er); subj.error(er); });
    return subj.asObservable();
  }

  getAvailabilityTypes(loaderId?: string) {
    if (!!this._cachedAvailabilityTypes)
      return of(this._cachedAvailabilityTypes);
    const subj = new Subject<AvailabilityType[]>();
    this.httpclient.get<AvailabilityType[]>(
      {
        url: this.baseApiUrl + '/getAvailabilityTypes',
        options: { withCredentials: true },
        loaderId: loaderId
      }, res => { this._cachedAvailabilityTypes = res; subj.next(res); },
      (er) => { this.errorHandlerService.handleError(er); subj.error(er); });
    return subj.asObservable();
  }

  createCatalog(catalog: Catalog, loaderId?: string) {
    const obj = {
      catalogName: catalog.CatalogueName,
      startDate: DateUtilities.FormatDateForAPI(catalog.StartDate),
      endDate: DateUtilities.FormatDateForAPI(catalog.EndDate),
      availabilityTypeId: catalog.AvailabilityTypeId,
      includeNewStyles: catalog.IncludeNewStyles,
      includeNewCompanies: catalog.IncludeNewCompanies
    };
    const subj = new Subject<number>();
    this.httpclient.post({
      url: this.baseApiUrl + '/CreateNewCatalog',
      body: obj, options: { withCredentials: true },
      loaderId: loaderId
    }, x => subj.next(x), (er) => { this.errorHandlerService.handleError(er); subj.error(er); });
    return subj.pipe(tap(() => this.messageService.openSnackBar('Catalog saved')));
  }

  updateCatalog(catalog: Catalog, loaderId?: string) {
    const obj = {
      catalogId: catalog.CatalogueId,
      catalogName: catalog.CatalogueName,
      availabilityTypeId: catalog.AvailabilityTypeId,
      startDate: DateUtilities.FormatDateForAPI(catalog.StartDate),
      endDate: DateUtilities.FormatDateForAPI(catalog.EndDate),
      includeNewStyles: catalog.IncludeNewStyles,
      canceled: catalog.Canceled,
      published: catalog.Published,
      includeNewCompanies: catalog.IncludeNewCompanies
    };
    const subj = new Subject<number>();
    this.httpclient.post(
      {
        url: this.baseApiUrl + '/UpdateCatalog',
        body: obj, options: { withCredentials: true },
        loaderId: loaderId
      }, x => subj.next(x), (er) => { this.errorHandlerService.handleError(er); subj.error(er); });
    return subj.pipe(tap(() => this.messageService.openSnackBar('Catalog saved')));
  }

  publishCatalog(catalogId: number, publish: boolean, loaderId?: string) {
    const params = new HttpParams({
      fromObject: {
        catalogId: catalogId.toString(),
        publish: publish + ''
      }
    });
    const subj = new Subject<number>();
    this.httpclient.get<number>({
      url: this.baseApiUrl + '/PublishCatalog',
      options: { params: params, withCredentials: true },
      loaderId: loaderId
    }, x => subj.next(x), (er) => { this.errorHandlerService.handleError(er); subj.error(er); });
    return subj.asObservable();
  }

  deleteCatalog(catalogId: number, loaderId?: string) {
    const subj = new Subject<boolean>();
    const params = new HttpParams({
      fromObject: {
        catalogId: catalogId.toString()
      }
    });
    this.httpclient.get({
      url: this.baseApiUrl + '/DeleteCatalog',
      options: { withCredentials: true, params: params },
      loaderId: loaderId
    }, x => subj.next(true), (er) => { this.errorHandlerService.handleError(er); subj.error(er); });
    return subj;
  }

  bindClusterToCatalog(catalogId: number, clusterIds: ObjectId[], loaderId?: string) {
    const params = new HttpParams({ fromObject: { catalogId: catalogId.toString() } });
    const subj = new Subject<number>();
    this.httpclient.post<number>({
      url: this.baseApiUrl + '/bindClusterToCatalog',
      options: { withCredentials: true, params: params },
      body: clusterIds,
      loaderId: loaderId
    }, data => subj.next(data), (er) => { this.errorHandlerService.handleError(er); subj.error(er); });
    return subj.asObservable();
  }

  unbindClusterToCatalog(catalogId: number, clusterIds: ObjectId[], loaderId?: string) {
    const params = new HttpParams({ fromObject: { catalogId: catalogId.toString() } });
    const subj = new Subject<number>();
    this.httpclient.post<number>({
      url: this.baseApiUrl + '/unbindClusterToCatalog',
      options: { withCredentials: true, params: params },
      body: clusterIds,
      loaderId: loaderId
    }, data => subj.next(data), (er) => { this.errorHandlerService.handleError(er); subj.error(er); });
    return subj.asObservable();
  }
}
