import { AfterViewInit, Component, OnInit } from '@angular/core';

import { SiteInfo } from '../site-navigation.models';
import { SiteNavigationService } from '../site-navigation.service';

@Component({
  templateUrl: './site-selector.component.html',
  styleUrls: ['./site-selector.component.scss']
})
export class SiteSelectorComponent implements OnInit {

  public sites: SiteInfo[] = [];
  public loaderId = 'site-loader-id';

  constructor(private siteNavigationService: SiteNavigationService) {
  }

  public ngOnInit() {
    this.siteNavigationService.getSites(this.loaderId).subscribe((siteInfos: SiteInfo[]) => {
      this.sites = siteInfos;
    });
  }
  public goTo(url: string) {
    location.href = url;
  }
}
