
export class Menu {
    MenuID: number;
    CultureInfo: string;
    MenuRoot: MenuRoot[];
}

export class MenuItems {
    Id: number;
    Description: string;
    ObjectTypeId: number;
    ParentObjectTypeId: number;
    ParentObjectId: number;
    Level: number;
    SubMenu: MenuItems[] = [];
}

export class MenuRoot extends MenuItems {
    Visible: boolean;
    ShowHighligts: boolean;
    ShowPromotions: boolean;

    constructor(init?: Partial<MenuRoot>) {
        super();
        Object.assign(this, init);
    }
}

export class Language {
    Label: string;
    CultureInfo: string;
    SrcImg: string;
}
