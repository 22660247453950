import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';

import { GridModule } from '@progress/kendo-angular-grid';
import { UiLoaderModule, UiStepperModule, UiSelectorModule, UiAvatarModule } from '@basic/libs';
import { ProductOverviewComponent } from './product-overview.component';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    GridModule,
    UiLoaderModule,
    MatSelectModule,
    MatFormFieldModule,
    MatButtonToggleModule,
    MatInputModule,
    UiStepperModule,
    UiSelectorModule,
    UiAvatarModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    MatTabsModule,
    MatRadioModule,
    MatChipsModule,
    MatCheckboxModule,
  ],
  declarations: [ProductOverviewComponent]
})
export class ProductOverviewModule { }
