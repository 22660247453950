import { Component, OnDestroy } from '@angular/core';
import { Current, PageResult } from '@basic/libs';
import { CatalogService } from 'src/app/features/catalog/catalog.service';
import { Catalog, Promotion } from 'src/app/shared/models/common.models';
import { PromotionService } from 'src/app/features/promotion/promotion.service';
import { Guid } from '@basic/libs';

import { Subscription } from 'rxjs';
import { ContextHandlerService } from 'src/app/shared/services/context-handler.service';
import { SiteDashboardInfo, ChartInfo } from '../site-navigation.models';
import { SiteNavigationService } from '../site-navigation.service';


@Component({
  selector: 'app-site-dashboard',
  templateUrl: './site-dashboard.component.html',
  styleUrls: ['./site-dashboard.component.scss']
})
export class SiteDashboardComponent implements OnDestroy {

  siteDashboardLoaderId: string = Guid.newGuid();
  dashboardInfo: SiteDashboardInfo;
  promotionChart: ChartInfo;
  catalogChart: ChartInfo;
  siteName = '';
  datiGrafico: number[];

  data: Current;

  private contextSubscription: Subscription;

  constructor(
    private contextService: ContextHandlerService,
    private catalogService: CatalogService,
    private promotionService: PromotionService,
    private siteNavigationService: SiteNavigationService
  ) {
    this.contextSubscription = this.contextService.getObservableContext().subscribe(ctx => {
      this.data = ctx.Current;
      const areaPicker = ctx.Current.Page.NavBarAreaPickerItems.find(
        x => x.SiteId === ctx.Current.SiteId
      );
      this.siteName = areaPicker.DisplayName;

      this.dashboardInfo = new SiteDashboardInfo();

      this.siteNavigationService.getSiteDashboardInfo(this.siteDashboardLoaderId).subscribe(info => {
        this.dashboardInfo = info;
        this.catalogChart = new ChartInfo();
        this.promotionChart = new ChartInfo();
        this.initChart(this.catalogChart, this.dashboardInfo.CatalogueTotalCount - this.dashboardInfo.PublishedCatalogueCount, this.dashboardInfo.PublishedCatalogueCount);
        this.initChart(this.promotionChart, this.dashboardInfo.PromotionTotalCount - this.dashboardInfo.PromotionPublishedTotalCount, this.dashboardInfo.PromotionPublishedTotalCount);
      });
    });


  }

  ngOnDestroy(): void {
    this.contextSubscription.unsubscribe();
  }


  private initChart(chart: ChartInfo, notActive: number, active: number) {
    // this.catalogChart = new ChartInfo();
    // const notActive = this.dashboardInfo.CatalogueTotalCount - this.dashboardInfo.PublishedCatalogueCount;
    // const active = this.dashboardInfo.PublishedCatalogueCount;

    chart.legend = true;
    chart.type = 'pie';
    chart.data = [notActive, active];
    chart.labels = ['Inactive', 'Active'];
    chart.options = {
      responsive: false,
    };
    chart.colors = [
      {
        backgroundColor: ['rgba(172, 170, 170, .6)', 'rgba(33, 177, 66, .6)'],
      },
    ];
  }
  private loadPublishedCatalogs() {
    this.catalogService.getCatalogs(0, 1, '', null, null, 'All').subscribe(
      (result: PageResult<Catalog>) => {
        this.catalogChart = new ChartInfo();
        const notActive = this.dashboardInfo.CatalogueTotalCount - this.dashboardInfo.PublishedCatalogueCount;
        const active = this.dashboardInfo.PublishedCatalogueCount;

        this.catalogChart.legend = true;
        this.catalogChart.type = 'pie';
        this.catalogChart.data = [notActive, active];
        this.catalogChart.labels = ['Inactive', 'Active'];
        this.catalogChart.options = {
          responsive: false,
        };

        this.catalogChart.colors = [
          {
            backgroundColor: ['rgba(172, 170, 170, .6)', 'rgba(33, 177, 66, .6)'],
          },
        ];

      }
    );
  }

  private loadPublishedPromotions() {
    this.promotionService.getPromotions(0, 1, '', null, null).subscribe(
      (promotions: PageResult<Promotion>) => {

        this.dashboardInfo.PromotionTotalCount = promotions.TotalResults;

        this.promotionChart = new ChartInfo();

        const notActive = this.dashboardInfo.PromotionTotalCount - this.dashboardInfo.PromotionPublishedTotalCount;
        const active = this.dashboardInfo.PromotionPublishedTotalCount;

        this.promotionChart.legend = true;
        this.promotionChart.type = 'pie';
        this.promotionChart.data = [notActive, active];
        this.promotionChart.labels = ['Inactive', 'Active'];
        this.promotionChart.options = { responsive: false };

        this.promotionChart.colors = [
          {
            backgroundColor: ['rgba(172, 170, 170, .6)', 'rgba(33, 177, 66, .6)'],
          },
        ];
      }
    );

  }
}
