import { OverlayModule } from '@angular/cdk/overlay';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  ContextService,
  CoreModule,
  ErrorModule,
  HttpAuthErrorsInterceptor,
  LayoutModule,
  UiDialogHttpErrorInterceptor,
} from '@basic/libs';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ParamsInterceptor } from './shared/services/params.interceptor';
import { MenuSiteSelectorModule } from './shared/components/menu-site-selector/menu-site-selector.module';
import { BreadcrumbModule } from './shared/components/breadcrumb/breadcrumb.module';
import { ChartsModule } from 'ng2-charts';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ErrorModule,
    CoreModule.forRoot(),
    BreadcrumbModule,
    LayoutModule,
    OverlayModule,
    FormsModule,
    MatIconModule,
    MenuSiteSelectorModule,
    ChartsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ParamsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpAuthErrorsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UiDialogHttpErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

  constructor(private contextService: ContextService) {
    if (environment) {
      this.initContext(environment.protocol, environment.urlPart);
    } else {
      console.error('Environment not loaded');
    }
  }

  private initContext(protocol: string, urlPart: string) {
    this.contextService.context.Settings.AppPaths.Core = {
      Base: protocol + '://accounts' + urlPart + '.basic.net/',
      Api: protocol + '://accounts' + urlPart + '.basic.net/'
    };
    this.contextService.context.Settings.AppPaths.Local = {
      Base: protocol + '://basiccms' + urlPart + '.basic.net/',
      Api: protocol + '://basiccms-api' + urlPart + '.basic.net/'
    };
    this.contextService.context.Settings.AppPaths.Global = {
      Api: protocol + '://globalapi' + urlPart + '.basic.net/'
    };
    this.contextService.context.Settings.AppPaths.BasicNet = {
      Base: protocol + '://my' + urlPart + '.basic.net/'
    };
    this.contextService.context.Settings.AppPaths.BasicApp = {
      Base: protocol + '://basicapp' + urlPart + '.basic.net/'
    };

    this.contextService.context.Settings.LoginUrl = this.contextService.context.Settings.AppPaths.Core.Base + 'account/login';
    this.contextService.context.Settings.LogoffUrl = this.contextService.context.Settings.AppPaths.Core.Base + 'account/clientlogoff';
  }
}
