<!-- Breadcrumb -->
<div class="breadcrumb">
  <h2>HOME</h2>
  <mat-icon>keyboard_arrow_right</mat-icon>
  <h2 class="current-page">MENU BUILDER</h2>
</div>

<div class="buttons margin-vertical">


  <div class="left-align">
    <b>Country: </b>
    <mat-button-toggle-group class="margin-right margin-left" appearance="legacy" [value]="languageSelected">
      <mat-button-toggle *ngFor="let language of languages" [value]="language"
        (click)="languageSelectedEvent(language)">
        <img class="flag" [src]="language.SrcImg"> {{language.Label}}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div class="right-align">
    <button mat-raised-button (click)="publish()" [disabled]="publishButtonDisabled">
      <mat-icon>check</mat-icon>Publish Menu
    </button>
  </div>

</div>

<ui-loader [loaderId]="menuRootLoader" [disableNoData]="true">
  <!-- Card -->
  <div class="card padding">
    <h2>TOP MENU OPTIONS</h2>

    <div cdkDropList cdkDropListOrientation="horizontal" class="option-list" (cdkDropListDropped)="drop($event)">

      <div class="option-box" *ngFor="let menuRootItem of menu.MenuRoot" cdkDrag>
        <div>{{menuRootItem.Description}}
        </div>
        <mat-icon class="pointer" (click)="openEditNameDialog(menuRootItem)">edit</mat-icon>
        <mat-icon class="pointer" (click)="openDeleteDialog(menuRootItem)">delete</mat-icon>

      </div>
    </div>

    <!-- Bottone -->
    <button mat-raised-button (click)="openAddOptionEditor()" color='primary'>
      <mat-icon>add_circle</mat-icon>Add Option
    </button>



  </div>



  <!-- Card -->
  <div class="card padding margin-top">
    <h2>SUB MENU OPTIONS</h2>


    <!-- Card -->

    <div class="card-white padding margin-bottom" *ngFor="let menuRootItem of menu.MenuRoot; let i = index" >
    
      <h2>{{menuRootItem.Description}}</h2>
     

      <!-- <div class="block-container"> -->

        <div cdkDropList cdkDropListOrientation="horizontal"  class="block-container" (cdkDropListDropped)="dropSubOption($event, i)">

        <div class="block margin-right">
          <div class="padding-small center"><b>HIGHLIGHTS</b></div>

          <div class="inner-container position">
            <div class="centering">
              <mat-slide-toggle color="primary" [(ngModel)]="menuRootItem.ShowHighligts" (change)="enablePublish()">Auto retrieve
              </mat-slide-toggle>
            </div>
          </div>
        </div>

        <div class="block margin-right margin-bottom movable" *ngFor="let item of menuRootItem.SubMenu"
          [ngClass]="{'empty': item.SubMenu.length === 0}" cdkDrag>
          

          <div *ngIf="!item.Description" class="padding-small center"><b>MENU-CATEGORY</b></div>
          <div *ngIf="item.Description" class="padding-small center"><b>{{item.Description}}</b></div>

          <button *ngIf="menuRootItem.SubMenu.indexOf(item) > 0 && item.Id === 0" mat-raised-button class="add centering"
            [disabled]="menuRootItem.SubMenu[menuRootItem.SubMenu.indexOf(item)-1].SubMenu.length === 0"
            (click)="openEditor(menuRootItem, item, false)">
            <mat-icon>add_circle</mat-icon>Add
          </button>

          <button *ngIf="menuRootItem.SubMenu.indexOf(item) === 0 && item.Id === 0" mat-raised-button class="add centering"
            (click)="openEditor(menuRootItem, item, false)">
            <mat-icon>add_circle</mat-icon>Add
          </button>

          <div class="inner-container mini-scrollbar padding-small">

            <div *ngFor="let option of item.SubMenu">

              <mat-checkbox [checked]="true" [disabled]="true">{{option.Description}}</mat-checkbox>

            </div>


          </div>

          <div class="button-edit margin-small center">
            <button mat-stroked-button (click)="openClearEditor(menuRootItem, item)">
              <mat-icon>delete</mat-icon>Clear
            </button>
            <button mat-stroked-button class="margin-left-small" (click)="openEditor(menuRootItem, item, true)">
              <mat-icon>edit</mat-icon>Edit
            </button>

          </div>

        </div>


        <div class="block margin-right">
          <div class="padding-small center"><b>PROMOTIONS</b></div>

          <div class="inner-container position">
            <div class="centering">
              <mat-slide-toggle [(ngModel)]="menuRootItem.ShowPromotions" (change)="enablePublish()" color="primary">Auto retrieve
              </mat-slide-toggle>
            </div>
          </div>
        </div>

      </div>

    </div>

  </div>
  <!-- </div> -->


</ui-loader>

<ng-template #modalEditCategory>
  <cms-add-dialog (close)="closeEdit()" (aply)="aply($event)" (categorySel)="updateCategory($event)"
    [subCategories]="subCategories" [categorySelected]="categorySelected" [menuRoot]="menu.MenuRoot"
    [subMenuSlot]="subMenuSlot"> </cms-add-dialog>
</ng-template>

<ng-template #modalEditName>
  <form [formGroup]="newLabelForm">
  <div class="modal-edit-name-content">
    Change <b>{{oldLabel}}</b>
    <mat-form-field class="margin-top" appearance="outline">
      <mat-label>New Label</mat-label>
      <input matInput formControlName="Name">
    </mat-form-field>
    <div class="error" *ngIf="newLabelForm.hasError('nameExist')">
      Name already exists. Please choose another name.
    </div>
  
  </div>


  <div class="right-align">
    <!-- Bottone -->
    <button mat-stroked-button color="warn" class="margin-right-small" (click)="closeEditName()">
      <mat-icon>close</mat-icon>Close
    </button>

    <!-- Bottone -->
    <button mat-raised-button [disabled]="!newLabelForm.valid" (click)="changeLabel()">
      <mat-icon>save</mat-icon>Apply
    </button>


  </div>
</form>
</ng-template>

<ng-template #modalDeleteOption>
  <div class="modal-delete-content">
    Are you sure you want to delete option from menu?
    <div class="error-text margin-top-small inline-block">
      <mat-icon [inline]="true" class="margin-right-small">info</mat-icon> By deleting a menu option all sub-menu
      options configuration will be lost.
    </div>
  </div>

  <div class="right-align">
    <!-- Bottone -->
    <button mat-stroked-button color="warn" class="margin-right-small" (click)="closeEdit()">
      <mat-icon>close</mat-icon>Close
    </button>

    <!-- Bottone -->
    <button mat-raised-button (click)="deleteOption()">
      <mat-icon>save</mat-icon>Delete
    </button>


  </div>
</ng-template>

<ng-template #clearDialog>
  <div class="modal-clear-content">
    Are you sure you want to remove this sub category?
  </div>

  <div class="right-align">
    <!-- Bottone -->
    <button mat-stroked-button color="warn" class="margin-right-small" (click)="closeEdit()">
      <mat-icon>close</mat-icon>Close
    </button>

    <!-- Bottone -->
    <button mat-raised-button (click)="clear()">
      <mat-icon>delete</mat-icon>Remove
    </button>


  </div>
</ng-template>