import { Injectable } from '@angular/core';
import { ContextService, Context } from '@basic/libs';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ContextHandlerService {

  private context: Context;
  private contextSubject: BehaviorSubject<Context> = new BehaviorSubject<Context>(null);

  constructor(
    private contextService: ContextService,
    private router: Router,
  ) {
    this.context = this.contextService.context;

    contextService.getObservableContext().subscribe((newContext: Context) => {
      this.context = newContext;
    });

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.contextSubject.next(this.context);
      }
    });
  }

  public getObservableContext(): Observable<Context> {
    return this.contextSubject.asObservable().pipe(filter(x => !!x));
  }
}
