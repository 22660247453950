import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuBuilderComponent } from './menu-builder.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GridModule } from '@progress/kendo-angular-grid';
import { UiLoaderModule, UiStepperModule, UiSelectorModule, UiAvatarModule } from '@basic/libs';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AddDialogComponent } from './add-dialog/add-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    GridModule,
    UiLoaderModule,
    MatSelectModule,
    UiStepperModule,
    UiSelectorModule,
    UiAvatarModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatRadioModule,
    MatChipsModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    DragDropModule,
    MatTooltipModule,
    MatTreeModule,
    ReactiveFormsModule,

  ],
  declarations: [
    MenuBuilderComponent,
    AddDialogComponent
  ]
})
export class MenuBuilderModule { }
