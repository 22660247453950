import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { loaderAnimation, ContextService, AreaPickerMenuItem } from '@basic/libs';
import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [loaderAnimation]
})
export class AppComponent {
  public title = 'app';
  public loading = true;
  public areas: AreaPickerMenuItem[] = [];
  public currentSiteId: number;
  constructor(private router: Router,
    private _activatedRoute: ActivatedRoute,
    private _titleService: Title,
    private _contextService: ContextService) {
    this.subscribeLoader();
    this.subscribeNavigationEnd();
    this._contextService.getObservableContext().subscribe(context => {
      if (context) {
        this.areas = context.Current.Page.NavBarAreaPickerItems;
        this.currentSiteId = context.Current.SiteId;
      }
    });
  }

  public changeArea(newArea: AreaPickerMenuItem) {
    const routes = this.createRoutingTree(this._activatedRoute.children, newArea.RouteName).filter(x => !!x && !!x.length);
    this.router.navigate([routes.join('/')]);
  }

  private subscribeLoader() {
    this.router.events.subscribe((val) => {
      setTimeout(() => {
        if (val instanceof NavigationStart) {
          this.loading = true;
        } else if (val instanceof NavigationEnd) {
          this.loading = false;
        }
      });
    });
  }

  private subscribeNavigationEnd() {
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        map(() => {
          let route = this._activatedRoute.firstChild;
          let child = route;

          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
              route = child;
            } else {
              child = null;
            }
          }

          return route;
        }),
        mergeMap(route => route.data)
      )
      .subscribe(data => {
        const title = data['title'];
        if (title) {
          this._titleService.setTitle(title);
        }
      });
  }

  private createRoutingTree(children: ActivatedRoute[], siteName: string) {
    let routes: string[] = children.filter(x => !!x.routeConfig).map(x => {
      return x.routeConfig.path !== ':siteName' ? x.routeConfig.path : siteName;
    });
    children.forEach(x => {
      routes = routes.concat(this.createRoutingTree(x.children, siteName));
    });
    return routes;
  }
}
