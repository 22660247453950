<up-header>
  <cms-menu-site-selector *ngIf="areas && areas.length > 0 && currentSiteId > 0"
                    [areas]="areas"
                    [currentSiteId]="currentSiteId"
                    (changeArea)="changeArea($event)">
  </cms-menu-site-selector>
</up-header>
<main>
  <div class="body-wrapper">
    <router-outlet></router-outlet>
  </div>

</main>
<up-footer></up-footer>
<!-- <section *ngIf="loading"
         [@loaderAnimation]>
  <div class="loader-wrapper">
    <div class="loader"></div>
    <div class="loader-section"></div>
  </div>
</section> -->
