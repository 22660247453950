import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AreaPickerMenuItem } from '../../models/common.models';

@Component({
  selector: 'cms-menu-site-selector',
  templateUrl: './menu-site-selector.component.html',
  styleUrls: ['./menu-site-selector.component.scss']
})
export class MenuSiteSelectorComponent implements OnInit {

  @Input() areas: AreaPickerMenuItem[];
  @Input() currentSiteId = -1;
  @Output() changeArea = new EventEmitter<AreaPickerMenuItem>();

  // displayedAreas: AreaPickerMenuItem[];
  currentArea: AreaPickerMenuItem;
  showMenu: boolean;

  get homeDashboard(): string { return '/b2b/' + this.currentArea.RouteName + '/dashboard'; }
  get displayedAreas() { return this.currentSiteId && this.areas.filter(a => a.SiteId !== this.currentSiteId); }

  constructor() { }

  ngOnInit() {
    this.loadItems();
  }

  navigateToArea(area: AreaPickerMenuItem) {
    this.showMenu = false;
    this.currentArea = area;
    this.changeArea.emit(area);
  }

  private loadItems() {
    this.currentArea = this.areas.find(a => a.SiteId === this.currentSiteId);
    // this.displayedAreas = this.areas.filter(a => a.SiteId !== this.currentSiteId);
  }

}
