import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreModule, UiLoaderModule } from '@basic/libs';

import { MainAreaSelectorComponent } from './main-area-selector/main-area-selector.component';
import { SiteDashboardComponent } from './site-dashboard/site-dashboard.component';
import { SiteNavigationService } from './site-navigation.service';
import { SiteSelectorComponent } from './site-selector/site-selector.component';
import { ChartsModule } from 'ng2-charts';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    MatCardModule,
    UiLoaderModule,
    MatButtonModule,
    RouterModule,
    MatIconModule,
    ChartsModule
  ],
  declarations: [
    SiteSelectorComponent,
    MainAreaSelectorComponent,
    SiteDashboardComponent
  ],
  providers: [
    SiteNavigationService,
  ],
  exports: [
    SiteDashboardComponent,
    SiteSelectorComponent
  ],

})
export class SiteNavigationModule { }
