import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { BasicHttpClientService, ContextService } from '@basic/libs';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';
import { MenuItems, Menu, Language } from './menu-builder.models';

@Injectable({
  providedIn: 'root'
})
export class MenuBuilderService {

  private baseApiUrl: string;


  constructor(
    private httpclient: BasicHttpClientService,
    private contextService: ContextService,
    private errorHandlerService: ErrorHandlerService,
  ) {
    this.baseApiUrl = this.contextService.context.Settings.AppPaths.Local.Api + '/MenuBuilder';
  }

  public getOptions(objectTypeId: number, hierarchy: boolean, loaderId?: string): Observable<MenuItems[]> {
    const resultSubject: Subject<MenuItems[]> = new Subject<MenuItems[]>();
    const params = new HttpParams({
      fromObject: {
        objectTypeId: objectTypeId.toString(),
        hierarchy: hierarchy.toString(),
      }
    });
    this.httpclient.get<MenuItems[]>(
      {
        url: this.baseApiUrl + '/GetOptions',
        options: { params: params, withCredentials: true },
        loaderId
      },
      (options: MenuItems[]) => resultSubject.next(options),
      (err: any) => this.errorHandlerService.handleError(err)
    );

    return resultSubject.asObservable();
  }

  public getMenuRoot(language: Language, loaderId?: string): Observable<Menu> {
    const resultSubject: Subject<Menu> = new Subject<Menu>();
    const params = new HttpParams({
      fromObject: {
        cultureInfo: language.CultureInfo
      }
    });
    this.httpclient.get<Menu>(
      {
        url: this.baseApiUrl + '/GetMenuRoot',
        options: {params: params, withCredentials: true },
        loaderId
      },
      (menu: Menu) => resultSubject.next(menu),
      (err: any) => this.errorHandlerService.handleError(err)
    );

    return resultSubject.asObservable();
  }

  publishMenu(menu: Menu, loaderId?: string): Observable<number> {
    const resultSubject: Subject<number> = new Subject();
    const body = {
      menu
    };
    this.httpclient.post<number>(
      {
        url: this.baseApiUrl + '/PublishMenu',
        options: { withCredentials: true },
        loaderId,
        body,
      },
      (result: number) => resultSubject.next(result),
      er => {
        this.errorHandlerService.handleError(er);
        resultSubject.error(er);
      }
    );
    return resultSubject.asObservable();
  }
  
}
