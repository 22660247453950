import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContextService } from '@basic/libs';
import { Observable } from 'rxjs';

@Injectable()
export class ParamsInterceptor implements HttpInterceptor {

  constructor(private contextService: ContextService) { }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const params = request.params;
    request = request.clone({
      setParams: {
        siteId: this.contextService.context.Current.SiteId ? this.contextService.context.Current.SiteId.toString() : '-1',
        pageId: params.get('pageId') ? params.get('pageId') : this.contextService.context.Current.Page.Id.toString(),
        objectId: params.get('objectId') ? params.get('objectId') : this.contextService.context.Current.Page.ObjectId.toString()
      }
    });

    return next.handle(request);
  }
}
