import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BasicError, BasicHttpClientService, ContextService, DateUtilities, PageResult, UiDialogService } from '@basic/libs';

import { Promotion, PromotionType } from '../../shared/models/common.models';
import { ErrorHandlerService } from '../../shared/services/error-handler.service';
import { PromotionRequirement } from './models/promotion-requirement';
import { Subject, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Ids } from 'src/app/shared/components/styles-selection/styles-selection.models';
import { Cluster } from '../companies-cluster/companies-cluster.models';


@Injectable({ providedIn: 'root' })
export class PromotionService {

  private baseApiUrl = this.contextService.context.Settings.AppPaths.Local.Api;
  private _promotionTypes: PromotionType[] = null;

  constructor(private httpclient: BasicHttpClientService,
    private contextService: ContextService,
    private errorHandlerService: ErrorHandlerService,
    private messageService: UiDialogService, ) { }

  getPromotions(currentPage?: number, pageSize?: number, searchText?: string, startDate?: Date, endDate?: Date, status?: string, loaderId?: string) {

    const subj = new Subject<PageResult<Promotion>>();
    const params = new HttpParams({
      fromObject: {
        currentPage: currentPage ? currentPage.toString() : '',
        pageSize: pageSize ? pageSize.toString() : '',
        searchText: searchText || '',
        startDate: startDate ? DateUtilities.FormatDateForAPI(startDate) : '',
        endDate: endDate ? DateUtilities.FormatDateForAPI(endDate) : '',
        status: status === 'Published' ? true.toString() : status === 'Unpublished' ? false.toString() : null
      }
    });
    this.httpclient.get<PageResult<Promotion>>({
      url: this.baseApiUrl + 'promotions/GetPromotions',
      options: { params: params, withCredentials: true },
      loaderId: loaderId
    },
      (pageResult) => { subj.next(pageResult); },
      (er) => { this.errorHandlerService.handleError(er); });
    return subj;
  }

  getPromotionById(promotionId: number, loaderId?: string) {
    const subj = new Subject<Promotion>();
    const params = new HttpParams({
      fromObject: {
        promotionId: promotionId.toString()
      }
    });
    this.httpclient.get<Promotion>({
      url: this.baseApiUrl + 'promotions/GetPromotionById',
      options: { params: params, withCredentials: true },
      loaderId: loaderId,
    }, (promo) => { subj.next(promo); },
      (er) => { this.errorHandlerService.handleError(er); });
    return subj.asObservable();
  }

  updatePromotion(promotion: Promotion, loaderId?: string) {
    const subj = new Subject<number>();
    const dataToSend: Promotion = Object.assign(promotion, {
      DateStart: DateUtilities.FormatDateForAPI(promotion.DateStart),
      DateEnd: DateUtilities.FormatDateForAPI(promotion.DateEnd)
    });

    this.httpclient.post({
      url: this.baseApiUrl + 'promotions/UpdatePromotion',
      body: dataToSend,
      options: { withCredentials: true },
      loaderId: loaderId
    }, (res) => subj.next(res), (er) => { this.errorHandlerService.handleError(er); });
    return subj.pipe(tap(() => this.messageService.openSnackBar('Promotion saved.')));
  }

  deletePromotion(promotionId: number, basicBankPromotionId: number = null, loaderId?: string) {
    const params = new HttpParams({
      fromObject: {
        promotionId: promotionId.toString(),
        basicBankPromotionId: basicBankPromotionId ? basicBankPromotionId.toString() : ''
      }
    });
    const subj = new Subject<number>();
    this.httpclient.get<number>({
      url: this.baseApiUrl + 'promotions/DeletePromotion',
      options: { params: params, withCredentials: true },
      loaderId: loaderId
    }, x => subj.next(x), (er) => { this.errorHandlerService.handleError(er); subj.error(er); });
    return subj.pipe(tap(() => this.messageService.openSnackBar('Promotion deleted.')));
  }

  publishPromotion(promotion: Promotion, loaderId?: string) {
    const subj = new Subject<number>();
    this.httpclient.post<number>({
      url: this.baseApiUrl + 'promotions/PublishPromotion',
      options: { withCredentials: true },
      body: promotion,
      loaderId: loaderId
    }, x => subj.next(x), (er) => { this.errorHandlerService.handleError(er); subj.error(er); });
    return subj.pipe(tap(() => this.messageService.openSnackBar(!promotion.Published ? 'Promotion published.' : 'Promotion unpublished')));
  }

  getPromotionTypes(loaderId?: string) {
    if (!this._promotionTypes) {
      const subj = new Subject<PromotionType[]>();
      this.httpclient.get<PromotionType[]>({
        url: this.baseApiUrl + 'promotions/GetPromotionTypes',
        options: { withCredentials: true },
        loaderId: loaderId,
      }, (res) => {
        this._promotionTypes = res;
        subj.next(this._promotionTypes);
      },
        (er) => { this.errorHandlerService.handleError(er); }
      );
      return subj.asObservable();
    } else {
      return of(this._promotionTypes);
    }
  }

  getPromotionRequirements(promotionId: number, loaderId?: string) {
    const params = new HttpParams({
      fromObject: {
        promotionId: promotionId.toString(),
      }
    });
    const subj = new Subject<PromotionRequirement[]>();
    this.httpclient.get<PromotionRequirement[]>({
      url: this.baseApiUrl + 'requirements/GetPromotionRequirements',
      options: { withCredentials: true, params: params },
      loaderId: loaderId,
    }, (data) => { subj.next(data); }, (er) => { this.errorHandlerService.handleError(er); });
    return subj.asObservable();
  }

  updatePromotionRequirement(requirement: PromotionRequirement, loaderId?: string) {
    const subj = new Subject<number>();
    this.httpclient.post<number>({
      url: this.baseApiUrl + 'requirements/UpdateRequirement',
      body: requirement,
      options: { withCredentials: true },
      loaderId: loaderId,
    }, (data) => { subj.next(data); }, (er) => {
      subj.error(er);
      this.errorHandlerService.handleError(er);
    });
    return subj.asObservable();
  }

  deletePromotionRequirement(requirementId: number, loaderId?: string) {
    const params = new HttpParams({
      fromObject: {
        requirementId: requirementId.toString(),
      }
    });
    const subj = new Subject<number>();
    this.httpclient.post<number>({
      url: this.baseApiUrl + 'requirements/DeleteRequirement',
      options: { withCredentials: true, params: params },
      loaderId: loaderId,
    }, (data) => { subj.next(data); }, (er) => {
      subj.error(er);
      this.errorHandlerService.handleError(er);
    });
    return subj.asObservable();
  }

  promotionClusterUpdate(referenceId: number, clustersIds: Ids[], loaderId?: string) {
    const subj = new Subject<number>();
    const params = new HttpParams({
      fromObject: {
        promotionId: referenceId.toString(),
      }
    });
    this.httpclient.post<number>({
      url: this.baseApiUrl + '/promotions/promotionClusterUpdate',
      options: { withCredentials: true, params: params },
      body: clustersIds,
      loaderId: loaderId
    }, data => subj.next(data), er => subj.error(er));
    return subj.asObservable();
  }

  promotionClusterGet(referenceId: number, loaderId?: string) {

    const subj = new Subject<Cluster[]>();
    const params = new HttpParams({
      fromObject: {
        promotionId: referenceId.toString(),
      }
    });
    this.httpclient.get<Cluster[]>({
      url: this.baseApiUrl + 'promotions/PromotionClusterGet',
      options: { params: params, withCredentials: true },
      loaderId: loaderId
    },
      (pageResult) => { subj.next(pageResult); },
      (er) => { this.errorHandlerService.handleError(er); });
    return subj;
  }

  promotionClusterDelete(referenceId: number, clustersIds: Ids[], loaderId?: string) {
    const subj = new Subject<number>();
    const params = new HttpParams({
      fromObject: {
        promotionId: referenceId.toString(),
      }
    });
    this.httpclient.post<number>({
      url: this.baseApiUrl + '/promotions/PromotionClusterDelete',
      options: { withCredentials: true, params: params },
      body: clustersIds,
      loaderId: loaderId
    }, data => subj.next(data), er => subj.error(er));
    return subj.asObservable();
  }

}
