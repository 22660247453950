import { Injectable } from '@angular/core';
import { BasicError } from '@basic/libs';
import { UiDialogService } from '@basic/libs';

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService {
  constructor(private messageService: UiDialogService) { }

  public handleError(error: BasicError): any {
    this.messageService.openSnackBar(error.message, '', 4000);
    console.error(error);
  }
}
