import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { UiDialogService, UiDialogSettings, Guid, Utilities, MenuItem } from '@basic/libs';
import { SelectionModel } from '@angular/cdk/collections';
import { MenuBuilderService } from './menu-builder.service';
import { MenuRoot, MenuItems, Menu, Language } from './menu-builder.models';
import { MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: 'cms-menu-builder',
  templateUrl: './menu-builder.component.html',
  styleUrls: ['./menu-builder.component.scss']
})

export class MenuBuilderComponent implements OnInit {

  @ViewChild('modalEditCategory') modalEditCategory: TemplateRef<any>;
  @ViewChild('modalEditName') modalEditName: TemplateRef<any>;
  @ViewChild('modalDeleteOption') modalDeleteOption: TemplateRef<any>;
  @ViewChild('clearDialog') clearDialog: TemplateRef<any>;

  subOptionsLoaderId: string = Guid.newGuid();
  menuRootLoader: string = Guid.newGuid();
  subCategories: MenuItems[];
  categorySelected: MenuItems = new MenuItems;
  item: MenuItem;
  newLabelForm: FormGroup;
  subOptions: MenuItems[];
  subOptionsTree: MenuItems[] = [];
  filteredData: MenuItems[];
  menuRootItemIndex: number;
  slotSubMenuIndex: number;
  subMenuSlot: MenuItems;

  menu: Menu = new Menu;

  publishButtonDisabled: boolean = true;
  gender: MenuItems;
  noCatSelected: MenuItems;
  oldLabel: string;
  checklistSelection = new SelectionModel<ExampleFlatNode>(true /* multiple */);
  languages: Language[] = [
    {
      Label: 'EN', CultureInfo: 'en-gb',
      SrcImg: 'https://icons.iconarchive.com/icons/wikipedia/flags/256/GB-United-Kingdom-Flag-icon.png'
    },
    {
      Label: 'IT', CultureInfo: 'it-it',
      SrcImg: 'https://icons.iconarchive.com/icons/wikipedia/flags/256/IT-Italy-Flag-icon.png'
    },
    {
      Label: 'FR', CultureInfo: 'fr-fr',
      SrcImg: 'http://icons.iconarchive.com/icons/wikipedia/flags/256/FR-France-Flag-icon.png'
    }
  ];
  languageSelected: Language;
  private dialog: MatDialogRef<any, any>;


  constructor(private messageService: UiDialogService, private menuBuilderService: MenuBuilderService,
    private dialogService: UiDialogService, private fb: FormBuilder) {
    this.newLabelForm = this.fb.group({
      Name: this.fb.control('', [Validators.required])
    }, {
      validators: [
        this.nameIsValid('Name', { nameExist: true }),
      ],
    });
  }

  ngOnInit() {
    this.languageSelected = this.languages[0];
    this.getMenu();
    this.getCategories();
  }

  getMenu() {
    this.menuBuilderService.getMenuRoot(this.languageSelected, this.menuRootLoader).subscribe(
      (result: Menu) => {
        if (result) {
          this.menu = result;
        } else {
          this.menu.CultureInfo = this.languageSelected.CultureInfo;
          this.menu.MenuRoot = [];
        }
      }
    );
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.menu.MenuRoot, event.previousIndex, event.currentIndex);
    this.publishButtonDisabled = false;
  }

  dropSubOption(event: CdkDragDrop<string[]>, index: number) {
    if (this.menu.MenuRoot[index].SubMenu[event.previousIndex].Id !== 0 && this.menu.MenuRoot[index].SubMenu[event.currentIndex].Id !== 0) {
      moveItemInArray(this.menu.MenuRoot[index].SubMenu, event.previousIndex, event.currentIndex);
      this.publishButtonDisabled = false;
    }
  }

  openEditor(menuRootItem: MenuRoot, subMenuSlot: MenuItems, edit?: boolean) {
    this.menuRootItemIndex = this.menu.MenuRoot.indexOf(menuRootItem);
    this.slotSubMenuIndex = this.menu.MenuRoot[this.menuRootItemIndex].SubMenu.indexOf(subMenuSlot);
    if (edit) {
      this.subMenuSlot = subMenuSlot;
      this.categorySelected = this.subCategories.find(elem => elem.Id === subMenuSlot.Id);
    } else {
      menuRootItem.SubMenu.forEach(category => {
        if (category.Id > 0) {
          const index = this.subCategories.indexOf(this.subCategories.find(cat => cat.Id === category.Id));
          this.subCategories.splice(index, 1);
        }
      });
      this.categorySelected = Utilities.DeepClone<MenuItems>(this.noCatSelected);

    }
    this.dialogService.openDialog(this.modalEditCategory);
  }

  openAddOptionEditor() {
    this.categorySelected = this.gender;
    this.dialogService.openDialog(this.modalEditCategory);
  }

  closeEditor() {
    this.getCategories();
    this.dialogService.closeDialog();
  }

  closeEdit() {
    this.dialogService.closeDialog();
  }

  closeEditName() {
    this.newLabelForm.controls.Name.setValue('');
    this.dialogService.closeDialog();
  }

  aply(itemsChecked: MenuItems[]) {
    if (this.categorySelected.Id === 0) {
      itemsChecked.forEach(option => {
        const item = (this.menu.MenuRoot.find(element => element.Description === option.Description));
        if (!item) {
          const optionToAdd: MenuRoot = {
            Id: option.Id,
            Description: option.Description,
            Level: option.Level,
            ObjectTypeId: option.ObjectTypeId,
            ParentObjectId: null,
            ParentObjectTypeId: null,
            ShowHighligts: false,
            ShowPromotions: false,
            Visible: false,
            SubMenu: []
          };
          this.menu.MenuRoot.push(optionToAdd);
          let x = 0;
          while (x < 4) {
            this.menu.MenuRoot[this.menu.MenuRoot.indexOf(optionToAdd)].SubMenu[x] = new MenuItems;
            this.menu.MenuRoot[this.menu.MenuRoot.indexOf(optionToAdd)].SubMenu[x].SubMenu = [];
            this.menu.MenuRoot[this.menu.MenuRoot.indexOf(optionToAdd)].SubMenu[x].Id = 0;
            x++;
          }
        }
      });
    } else {
      if (itemsChecked.length > 0) {
        this.menu.MenuRoot[this.menuRootItemIndex].SubMenu[this.slotSubMenuIndex] = Utilities.DeepClone<MenuItems>(/*this.filteredData[0]*/this.categorySelected);
      } else {
        this.menu.MenuRoot[this.menuRootItemIndex].SubMenu[this.slotSubMenuIndex] = new MenuItems;
        this.menu.MenuRoot[this.menuRootItemIndex].SubMenu[this.slotSubMenuIndex].SubMenu = [];
      }
      this.menu.MenuRoot[this.menuRootItemIndex].SubMenu[this.slotSubMenuIndex].SubMenu = Utilities.DeepClone<MenuItems[]>(itemsChecked);
    }
    this.enablePublish();
    this.closeEditor();
  }

  changeLabel() {
    this.menu.MenuRoot[this.menuRootItemIndex].Description = this.newLabelForm.controls.Name.value;
    this.newLabelForm.controls.Name.setValue('');
    this.enablePublish();
    this.dialog.close();
  }

  publish() {
    this.menuBuilderService.publishMenu(this.menu, this.menuRootLoader).subscribe(
      (result: number) => {
        this.getMenu();
      }
    );
  }

  openEditNameDialog(menuRootItem: MenuRoot) {
    this.oldLabel = menuRootItem.Description;
    this.menuRootItemIndex = this.menu.MenuRoot.indexOf(menuRootItem);
    this.dialog = this.messageService.openDialog({
      title: 'Edit Label',
      body: this.modalEditName,
    }, new UiDialogSettings({ IsFullScreen: false }));
  }

  deleteOption() {
    this.menu.MenuRoot.splice(this.menuRootItemIndex, 1);
    this.enablePublish();
    this.dialog.close();
  }

  openDeleteDialog(menuRootItem: MenuRoot) {
    this.menuRootItemIndex = this.menu.MenuRoot.indexOf(menuRootItem);
    this.dialog = this.messageService.openDialog({
      title: 'Delete Option',
      body: this.modalDeleteOption,
    }, new UiDialogSettings({ IsFullScreen: false }));
  }

  updateCategory(newCategory: MenuItems) {
    this.categorySelected = newCategory;
  }

  languageSelectedEvent(language: Language) {
    this.languageSelected = language;
    this.publishButtonDisabled = true;
    this.getMenu();
  }

  enablePublish() {
    this.publishButtonDisabled = false;
  }

  clear() {
    this.menu.MenuRoot[this.menuRootItemIndex].SubMenu.splice(this.slotSubMenuIndex, 1);
    this.menu.MenuRoot[this.menuRootItemIndex].SubMenu[3] = new MenuItems;
    this.menu.MenuRoot[this.menuRootItemIndex].SubMenu[3].Id = 0;
    this.menu.MenuRoot[this.menuRootItemIndex].SubMenu[3].SubMenu = [];
    this.enablePublish();
    this.dialog.close();
  }

  openClearEditor(menuRootItem: MenuRoot, subMenuSlot: MenuItems) {
    this.menuRootItemIndex = this.menu.MenuRoot.indexOf(menuRootItem);
    this.slotSubMenuIndex = this.menu.MenuRoot[this.menuRootItemIndex].SubMenu.indexOf(subMenuSlot);
    this.dialog = this.messageService.openDialog({
      title: 'Clear Category',
      body: this.clearDialog,
    }, new UiDialogSettings({ IsFullScreen: false }));
  }


  private getCategories() {

    this.gender = {
      Id: 0,
      Description: 'Gender',
      Level: 0,
      ObjectTypeId: 7,
      ParentObjectTypeId: null,
      ParentObjectId: null,
      SubMenu: null
    };
    this.noCatSelected = {
      Id: 0,
      Description: '',
      Level: 0,
      ObjectTypeId: 7,
      ParentObjectTypeId: null,
      ParentObjectId: null,
      SubMenu: null
    };
    this.menuBuilderService.getOptions(3, false).subscribe(
      (result: MenuItems[]) => {
        this.subCategories = result;

        this.subCategories.push(this.gender);
        this.categorySelected = this.gender;
      }
    );
  }

  private nameIsValid(field: string, validatorField: { [key: string]: boolean }): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      const newName = c.get(field).value;
      if (newName && this.menu && this.menu.MenuRoot.find(item => item.Description.toLocaleLowerCase() === newName.toLocaleLowerCase())) {
        return validatorField;
      }
      return null;
    };
  }

}
