import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplatesComponent } from './templates.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { CollectionsListComponent } from './collections-list/collections-list.component';
import { CollectionDetailComponent } from './collection-detail/collection-detail.component';
import { RouterModule } from '@angular/router';
import { CoreModule, UiAdvancedSearchModule, UiLoaderModule, UiSelectorModule, UiStepperModule } from '@basic/libs';
import { FormsModule } from '@angular/forms';
import { GridModule } from '@progress/kendo-angular-grid';
import { ChartsModule } from 'ng2-charts';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PromotionHomeComponent } from './promotion-home/promotion-home.component';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { PromotionListComponent } from './promotion-list/promotion-list.component';
import { MatTreeModule } from '@angular/material/tree';
import { PermissionManagerFirstComponent } from './permission-manager-first/permission-manager-first.component';
import { PermissionManagerSecondComponent } from './permission-manager-second/permission-manager-second.component';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    CoreModule,
    FormsModule,
    MatTabsModule,
    MatSidenavModule,
    UiAdvancedSearchModule,
    UiLoaderModule,
    MatPaginatorModule,
    UiSelectorModule,
    MatIconModule,
    MatInputModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatRadioModule,
    MatTreeModule,
    GridModule,
    MatSelectModule,
    MatButtonModule,
    UiStepperModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatExpansionModule,
    ChartsModule
  ],
  declarations: [TemplatesComponent, ProductDetailComponent, CollectionsListComponent, CollectionDetailComponent, PromotionHomeComponent, PromotionListComponent, PermissionManagerFirstComponent, PermissionManagerSecondComponent]
})
export class TemplatesModule { }
