import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MenuSiteSelectorComponent } from './menu-site-selector.component';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    MatIconModule,
    CommonModule,
    RouterModule
  ],
  declarations: [
    MenuSiteSelectorComponent
  ],
  exports: [MenuSiteSelectorComponent]
})
export class MenuSiteSelectorModule {
}
