import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CmsAuthGuardService } from './shared/auth/CmsAuthGuardService';
import { SiteDashboardComponent } from './features/site-navigation/site-dashboard/site-dashboard.component';
import { SiteNavigationModule } from './features/site-navigation/site-navigation.module';
import { SiteSelectorComponent } from './features/site-navigation/site-selector/site-selector.component';
import { MenuBuilderComponent } from './features/menu-builder/menu-builder.component';
import { MenuBuilderModule } from './features/menu-builder/menu-builder.module';
import { ProductOverviewComponent } from './features/product-overview/product-overview.component';
import { ProductOverviewModule } from './features/product-overview/product-overview.module';
import { TemplatesComponent } from './features/templates/templates.component';
import { TemplatesModule } from './features/templates/templates.module';


export const routes: Routes = [
  {
    path: 'b2b',
    children: [
      {
        path: ':siteName', children: [
          {
            path: 'manage/catalogs',
            loadChildren: () => import('./routes/catalogs.routing').then(m => m.CatalogsRoutingModule),
            canActivate: [CmsAuthGuardService]
          },
          {
            path: 'manage/promotions',
            loadChildren: () => import('./routes/promotions.routing').then(m => m.PromotionRoutingModule),
            // loadChildren: './routes/promotions.routing#PromotionRoutingModule',
            canActivate: [CmsAuthGuardService]
          },

          {
            path: 'manage/publications',
            loadChildren: () => import('./routes/publication.routing').then(m => m.PublicationRoutingModule),
            // loadChildren: './routes/publication.routing#PublicationRoutingModule',
            canActivate: [CmsAuthGuardService]
          },
          {
            path: 'manage/showcases',
            loadChildren: () => import('./routes/showcases.routing').then(m => m.ShowcasesRoutingModule),
            // loadChildren: './routes/showcases.routing#ShowcasesRoutingModule',
            canActivate: [CmsAuthGuardService]
          },
          {
            path: 'company/catalog',
            loadChildren: () => import('./routes/companies.routing').then(m => m.CompaniesRoutingModule),
            // loadChildren: './routes/companies.routing#CompaniesRoutingModule',
            canActivate: [CmsAuthGuardService]
          },
          {
            path: 'company/cluster',
            loadChildren: () => import('./routes/companies-cluster.routing').then(m => m.CompaniesClusterRoutingModule),
            // loadChildren: './routes/companies-cluster.routing#CompaniesClusterRoutingModule',
            canActivate: [CmsAuthGuardService],
          },
          {
            path: 'manage/users',
            loadChildren: () => import('./routes/user.routing').then(m => m.UserRoutingModule),
            // loadChildren: './routes/user.routing#UserRoutingModule',
            canActivate: [CmsAuthGuardService]
          },
          {
            path: 'shop/limits',
            loadChildren: () => import('./routes/shop-limits.routing').then(m => m.ShopLimitsRoutingModule),
            // loadChildren: './routes/shop-limits.routing#ShopLimitsRoutingModule',
            canActivate: [CmsAuthGuardService]
          },
          {
            path: 'style/abolished',
            loadChildren: () => import('./routes/abolished-styles.routing').then(m => m.AbolishedStylesRoutingModule),
            // loadChildren: './routes/abolished-styles.routing#AbolishedStylesRoutingModule',
            canActivate: [CmsAuthGuardService]
          },
          {
            path: 'company/checkin',
            loadChildren: () => import('./routes/companies-check-in.routing').then(m => m.CompaniesCheckInRoutingModule),
            // loadChildren: './routes/companies-check-in.routing#CompaniesCheckInRoutingModule',
            canActivate: [CmsAuthGuardService]
          },
          {
            path: 'warehouse',
            loadChildren: () => import('./routes/warehouse.routing').then(m => m.WarehouseRoutingModule),
            // loadChildren: './routes/warehouse.routing#WarehouseRoutingModule',
            canActivate: [CmsAuthGuardService],
            data: { title: 'B2B | Warehouse' }
          },
          {
            path: 'costonshelf',
            loadChildren: () => import('./routes/cost-on-shelf.routing').then(m => m.CostOnShelfRoutingModule),
            // loadChildren: './routes/warehouse.routing#WarehouseRoutingModule',
            canActivate: [CmsAuthGuardService],
            data: { title: 'B2B | Cost on shelf' }
          },
          {
            path: 'company/cluster-assignment',
            loadChildren: () => import('./routes/companies-cluster-assignment.routing').then(m => m.CompaniesClusterAssignmentRoutingModule),
            // loadChildren: './routes/companies-cluster-assignment.routing#CompaniesClusterAssignmentRoutingModule',
            canActivate: [CmsAuthGuardService],
          },
          {
            path: 'dashboard',
            component: SiteDashboardComponent,
            canActivate: [CmsAuthGuardService],
            data: { title: 'B2B | Site dashboard' }
          },
          {
            path: 'menu/menu-builder',
            canActivate: [CmsAuthGuardService],
            component: MenuBuilderComponent,
            data: { title: 'B2B | Menu Builder' }
          },
          {
            path: 'templates',
            loadChildren: () => import('./routes/templates.routing').then(m => m.TemplatesRoutingModule),
            // loadChildren: './routes/templates.routing#TemplatesRoutingModule',
            data: { title: 'B2B | Templates' }
          },
          {
            path: 'product-overview',
            component: ProductOverviewComponent,
            data: { title: 'B2B | Site dashboard' }
          },
          { path: '', pathMatch: 'full', redirectTo: 'dashboard' }
        ]
      },
      {
        path: '',
        pathMatch: 'full',
        component: SiteSelectorComponent,
        canActivate: [CmsAuthGuardService],
        data: { title: 'B2B | Sites' },
      }
    ]
  },
  {
    path: 'b2c/styles-sync',
    canActivate: [CmsAuthGuardService],
    loadChildren: () => import('./routes/styles-sync.routing').then(m => m.StylesSyncRoutingModule)
  },
  {
    path: 'b2c/users',
    canActivate: [CmsAuthGuardService],
    loadChildren: () => import('./routes/b2c-users.routing').then(m => m.B2CUsersRoutingModule)
  },
  { path: '', redirectTo: 'b2b', pathMatch: 'full' }
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    SiteNavigationModule,
    MenuBuilderModule,
    ProductOverviewModule,
    TemplatesModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
