import {
  HttpClient,
  HttpErrorResponse,
  HttpParams
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  NavigationEnd
} from '@angular/router';
import {
  AreaPickerMenuItem,
  AuthGuardService,
  ContextService,
  Utilities
} from '@basic/libs';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { EnvironmentType } from '../../../environments/environment-type';

@Injectable({ providedIn: 'root' })
export class CmsAuthGuardService extends AuthGuardService {
  private httpClient: HttpClient;
  private contextService: ContextService;

  private currentSiteName: string;
  constructor(
    _httpClient: HttpClient,
    _contextService: ContextService,
    _router: Router
  ) {
    super(_httpClient, _contextService, _router);
    this.httpClient = _httpClient;
    this.contextService = _contextService;
  }

  public canActivate(currentRoute: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    this.currentSiteName = currentRoute.params.siteName;
    let pageUrl = this.contextService.context.Settings.AppPaths.Local.Base;
    currentRoute.pathFromRoot.forEach(x => {
      if (x.routeConfig) {
        pageUrl += '/' + x.routeConfig.path;
      }
    });
    const params = new HttpParams({
      fromObject: {
        url: Utilities.CleanUrl(pageUrl)
      }
    });
    return this.httpClient
      .get(this.contextService.context.Settings.AppPaths.Core.Api + 'account/GetCmsPageAuthContext',
        { params: params, withCredentials: true })
      .pipe(
        map(res => {
          if (!!res) {
            try {
              this.mapContext(res);
            } catch (er) {
              super.handleHttpError(500);
              return false;
            }
          }
          return true;
        }),
        catchError((err: HttpErrorResponse) => {
          super.handleHttpError(err.status);
          return throwError(false);
        })
      );
  }

  private mapContext(pageInfo: any) {
    this.contextService.context.Current = pageInfo;
    if (this.currentSiteName) {
      this.contextService.context.Current.SiteId = this.getSiteIdBySiteName(
        this.currentSiteName
      );
      this.contextService.context.Current.SiteName = this.currentSiteName;
    }
    this.contextService.context.Current.User = super.adjustUserInfo(
      pageInfo.User
    );

    if (this.contextService.context.Current.SiteId <= 0) {
      throw Error('SiteId must be > 0');
    }

    if (environment.type !== EnvironmentType.production) {
      console.table({
        SiteId: this.contextService.context.Current.SiteId,
        UserId: this.contextService.context.Current.User.Id,
        ProfileId: this.contextService.context.Current.User.CurrentProfileId,
        PageId: this.contextService.context.Current.Page.Id
      });
    }
    this.contextService.setObservableContext(this.contextService.context);
  }

  private getSiteIdBySiteName(siteName: string): number {
    if (
      this.contextService.context.Current.Page &&
      this.contextService.context.Current.Page.NavBarAreaPickerItems
    ) {
      const areas: AreaPickerMenuItem[] = this.contextService.context.Current
        .Page.NavBarAreaPickerItems;
      if (
        areas &&
        areas.findIndex(
          a => a.RouteName.toLowerCase() === siteName.toLowerCase()
        ) >= 0
      ) {
        return areas.find(
          a => a.RouteName.toLowerCase() === siteName.toLowerCase()
        ).SiteId;
      }
    }
    console.error('Page info not found');
    throw Error('This brand is not available');
  }
}
