<h2>Select filters</h2>
<div class="modal-content">

  <mat-form-field appearance="outline">
    <mat-label>Select Category</mat-label>
    <mat-select [disabled]="disableSelect"
                [value]="categorySelected"
                (selectionChange)="subCategorySelected($event)">
      <mat-option *ngFor="let subCategory of subCategories"
                  [value]="subCategory" [disabled]="subCategory.Id === 0"
                 >
        {{ subCategory.Description }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="margin-top">Select suboptions:</div>
  <ui-loader [loaderId]="subOptionsLoaderId">
    <div class="check padding-small">
      <mat-checkbox *ngIf="show"(change)="selectAllSubOptions($event)">Select All</mat-checkbox>
      <mat-tree [dataSource]="dataSource"
                [treeControl]="treeControl">
        <!--   This is the tree node template for leaf nodes -->
        <mat-tree-node *matTreeNodeDef="let node"
                       matTreeNodePadding
                       [matTreeNodePaddingIndent]=10>
          <!-- use a disabled button to provide padding for tree leaf -->
          <button mat-icon-button
                  disabled></button>
          <mat-checkbox class="checklist-leaf-node"
                        [checked]="checklistSelection.isSelected(node)"
                        (change)="todoItemSelectionToggle(node)">{{node.name}}</mat-checkbox>
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-tree-node *matTreeNodeDef="let node;when: hasChild"
                       matTreeNodePadding
                       [matTreeNodePaddingIndent]=20>
          <button mat-icon-button
                  matTreeNodeToggle
                  [disabled]="true"
                  [attr.aria-label]="'toggle ' + node.name">
            <!-- <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon> -->
          </button>
          <mat-checkbox [checked]="checklistSelection.isSelected(node)"
                        (change)="todoItemSelectionToggle(node)">{{node.name}}</mat-checkbox>
        </mat-tree-node>
      </mat-tree>



    </div>
  </ui-loader>


</div>


<div class="right-align">
  <!-- Bottone -->
  <button mat-stroked-button
          color="warn"
          class="margin-right-small"
          (click)="closeDialog()">
    <mat-icon>close</mat-icon>Close
  </button>

  <!-- Bottone -->
  <button *ngIf="initialSelectionLength" mat-raised-button [disabled]="disableAply"
          (click)="save()">
    <mat-icon>save</mat-icon>Apply
  </button>
  <button *ngIf="!initialSelectionLength" mat-raised-button [disabled]="checklistSelection.selected.length === 0"
  (click)="save()">
<mat-icon>save</mat-icon>Apply
</button>


</div>