import { Injectable } from '@angular/core';
import { BasicError, BasicHttpClientService, ContextService } from '@basic/libs';

import { ErrorHandlerService } from '../../shared/services/error-handler.service';
import { SiteDashboardInfo, SiteInfo } from './site-navigation.models';
import { Subject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SiteNavigationService {

  private baseApiUrl: string;

  constructor(private httpclient: BasicHttpClientService,
    private contextService: ContextService,
    private errorHandlerService: ErrorHandlerService) {
    this.baseApiUrl = this.contextService.context.Settings.AppPaths.Local.Api + '/SiteSelection';
  }

  public getSites(loaderId?: string): Observable<SiteInfo[]> {
    const resultSubject: Subject<SiteInfo[]> = new Subject<SiteInfo[]>();
    this.httpclient.get<SiteInfo[]>(
      {
        url: this.baseApiUrl + '/GetSiteList',
        options: { withCredentials: true },
        loaderId: loaderId
      },
      (siteInfos: SiteInfo[]) => resultSubject.next(siteInfos),
      (err: any) => this.errorHandlerService.handleError(err)
    );

    return resultSubject.asObservable();
  }

  public getSiteDashboardInfo(loaderId?: string) {
    return new Observable<SiteDashboardInfo>(subs => {
      this.httpclient.get<SiteDashboardInfo>(
        {
          url: this.baseApiUrl + '/GetSiteDashboardInfo',
          options: { withCredentials: true },
          loaderId: loaderId
        }, (res: SiteDashboardInfo) => subs.next(res), err => subs.error(err));
    });
  }
}
