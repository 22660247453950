import { ChartDataSets, ChartOptions } from 'chart.js';
import { Label, Color } from 'ng2-charts';

export class SiteInfo {
  public B2BUrl: string;
  public ImageUrl: string;
  public RouteName: string;
  public SiteId: number;
  public SiteName: string;
}

export class SiteDashboardInfo {
  public CollectionCountTotal: number;
  public CatalogueTotalCount: number;
  public PromotionTotalCount: number;
  public PendingRequestTotalCount: number;
  public CollectionSchemasTotalCount: number;
  public PromotionPublishedTotalCount: number;
  public PublishedCatalogueCount: number;
}


export class ChartInfo {
  data: number[];
  options: ChartOptions;
  labels: Label[];
  colors: Color[];
  legend: boolean;
  type: string;

  constructor(init?: Partial<ChartInfo>) {
    Object.assign(this, init);
  }
}
